export const useFacebookSDK = () => {
  FB.init({
    appId: useRuntimeConfig().public.facebookClientId,
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v18.0'
  })

  return FB
}
