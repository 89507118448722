<template>
  <button
    class="flex justify-center items-center gap-[10px] text-white h-[42px] rounded-[3px] basis-full md:basis-auto p-2 bg-[#1D52A1] cursor-pointer"
    :disabled="loading"
    @click.stop.prevent="() => (props.onClick ? props.onClick() : login())"
  >
    <IconsSocialsFacebookMini class="h-4 w-4" />
    <span class="uppercase text-xs font-medium"> facebook </span>
  </button>
</template>

<script setup>
import { useAuthStore } from '~~/stores/auth'
import { useUserStore } from '~~/stores/user'
const authStore = useAuthStore()
const userStore = useUserStore()
const loading = inject('loading')
const isRemember = inject('isRemember')
const redirect = inject('redirect')
const props = defineProps({
  onClick: {
    type: Function,
    default: null
  }
})
const options = computed(() => ({
  redirect: redirect?.value,
  isRemember: isRemember?.value,
  onFinally: () => {
    if (loading) {
      loading.value = false
    }
  }
}))
const handleOnSuccess = async response => {
  const accessToken = response?.authResponse?.accessToken
  if (!accessToken) {
    console.error('Access token not found')
    return false
  }
  if (loading) {
    loading.value = true
  }
  if (!authStore.loggedIn) {
    await authStore.facebook({ accessToken }, { ...options.value })
  } else {
    await userStore.connectFacebook({ accessToken }, { ...options.value })
  }
  if (loading) {
    loading.value = false
  }
}

const login = () => {
  const facebook = useFacebookSDK()

  facebook.login(
    res => {
      if (res.status === 'connected') {
        handleOnSuccess(res)
      }
    },
    {
      scope: 'email'
    }
  )
}
</script>
