<template>
  <button
    class="flex justify-center items-center gap-[10px] text-white h-[42px] rounded-[3px] basis-full md:basis-auto p-2 bg-[#EA4335] cursor-pointer"
    :disabled="!isReady"
    @click.stop.prevent="() => (props.onClick ? props.onClick() : login())"
  >
    <IconsSocialsGoogleMini class="h-4 w-4" />
    <span class="uppercase text-xs font-medium"> google </span>
  </button>
</template>

<script setup>
import { useCodeClient } from 'vue3-google-signin'
import { useAuthStore } from '~~/stores/auth'
import { useUserStore } from '~~/stores/user'
const props = defineProps({
  onClick: {
    type: Function,
    default: null
  }
})
// const props = defineProps({
//   onSuccess: {
//     type: Function,
//     default: null
//   }
// })

const authStore = useAuthStore()
const userStore = useUserStore()
const loading = inject('loading')
const isRemember = inject('isRemember')
const redirect = inject('redirect')

const options = computed(() => ({
  scope: [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile'
  ],
  redirect_uri: 'postmessage',
  redirect: redirect?.value,
  isRemember: isRemember?.value,
  onFinally: () => {
    if (loading) {
      loading.value = false
    }
  }
}))
const handleOnSuccess = async response => {
  if (loading) {
    loading.value = true
  }
  const payload = {
    code: response.code,
    redirectUri: options.value.redirect_uri
  }
  if (!authStore.loggedIn) {
    await authStore.google(payload, { ...options.value })
  } else {
    await userStore.connectGoogle(payload, { ...options.value })
  }
  if (loading) {
    loading.value = false
  }
}

const handleOnError = errorResponse => {
  console.log('Error: ', errorResponse)
}
const { isReady, login } = useCodeClient({
  ...options.value,
  onSuccess: handleOnSuccess,
  onError: handleOnError
  // other options
})
</script>
